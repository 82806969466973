<template>
    <div class="person-select">
        <div class="person-select-title">
            <div>角色信息</div>
        </div>
        <div class="person-name">
            <div>{{personName}}</div>
        </div>
        <div class="person-detail">
            <div>
                <div class="swiper-button-prev-self"></div>
            </div>
            <div class="swiper-container gallery-top" ref="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"   v-for="item,k in avatarList" :key="k">
                        <img :src="item.personUrl"/>
                    </div>
                </div>
            </div>
            <div>
                <div  class="swiper-button-next-self"></div>
            </div>
        </div>
        <div class="person-tab-select">
            <div class="gallery-thumbs person-avatar-list" ref="swiper">
                <div class="swiper-wrapper person-avatar-list-wrapper">
                    <div class="swiper-slide person-avatar-list-item"  v-for="item,k in avatarList" :key="k">
                        <div>
                            <img :src="item.avatarUrl"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="person-confirm">
            <div @click="confirmSelect"></div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper'
export default {
    name: 'personSelect',
    data(){
        return{
            avatarList:[{
                id: 3,
                name:"角色3",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh3.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/3.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh3.glb",
                checked: true
            },{
                id: 1,
                name:"角色1",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh1.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/1.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh1.glb",
                checked: false
            },{
                id: 2,
                name:"角色2",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh2.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/2.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh2.glb",
                checked: false
            },{
                id: 4,
                name:"角色4",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh4.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/4.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh4.glb",
                checked: false
            },{
                id: 5,
                name:"角色5",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh5.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/5.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh5.glb",
                checked: false
            }],
            personName: "角色3",
            personUrl: "https://vr7niu.16fw.cn/person-select/3.png",
            personGlb: "https://vr7niu.16fw.cn/person-select/Mesh3.glb"
        }
    },
    created(){
        document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
    },
    mounted(){
        var galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true
        });

        var galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next-self',
                prevEl: '.swiper-button-prev-self',
            },
            thumbs: {
                swiper: galleryThumbs
            },
             on: {
                slideChange: (e) => {
                    const c_swiper = document.getElementsByClassName("gallery-top")
                    let swiperIndex = c_swiper[0].swiper.activeIndex
                    console.log("轮播图滚动事件 --->",swiperIndex)
                    this.personGlb = this.avatarList[swiperIndex].personGlb
                    this.personName = this.avatarList[swiperIndex].name
                }
            },
        });
    },
    methods:{

        confirmSelect(){
            let glb = this.personGlb
            // window.game.createCharacter(glb)
            this.$emit('showLoading');
        }
    }
}
</script>
<style>
    html,body {
        height: 100%;
        margin: 0px;
        padding: 0px;
        background-color: #04081A;
    }
    .person-select{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: #04081A;
        overflow-x:hidden;
        overflow-y:scroll;
    }
    .person-select > .person-select-title{
        height: 1.6rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .person-select > .person-select-title > div{
        width: 1.8rem;
        height: 0.4rem;
        font-size: 0.42rem;
        line-height: 0.4rem;
        color: white;
    }


    .person-select > .person-name{
        width: 100%;
        height: 0.84rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .person-select > .person-name > div{
        width: 2.72rem;
        height: 0.84rem;
        border-radius: 0.09rem;
        background-color: #101627;
        box-shadow: inset 0 0.5px 0.5px hsl(0deg 0% 100% / 24%);
        color: white;
        text-align: center;
        font-size: 0.03rem;
        line-height: 0.84rem;
    }
    .person-select > .person-detail{
        width: 6.9rem;
        height: 6.23rem;
        padding: 0.58rem 0.3rem 0.19rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .person-select > .person-detail > div:first-child{
        width: 0.6rem;
        height: 6.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .person-select > .person-detail > div:first-child > div{
        width: 0.6rem;
        height: 0.6rem;
        background: url('/bgi/person-select/left-select.png') no-repeat center center;
        background-size: cover;
    }

    .person-select > .person-detail > div:nth-child(2){
        width: 5.7rem;
        height: 6.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .person-select > .person-detail > div:nth-child(2) > div{
   
        height: 6.24rem;
    }
    .person-select > .person-detail > div:nth-child(2) > div> div{
        width: 2.22rem;
        height: 6.24rem;
    }
    .person-select > .person-detail > div:nth-child(2) > div > div > img{
        width: 2.22rem;
        height: 6.24rem;
        object-fit: cover;

    }
    .person-select > .person-detail > div:nth-child(3){
        width: 0.6rem;
        height: 6.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .person-select > .person-detail > div:nth-child(3) > div{
        width: 0.6rem;
        height: 0.6rem;
        background: url('/bgi/person-select/right-select.png') no-repeat center center;
        background-size: cover;
    }


    .person-select > .person-tab-select{
        height: 2.07rem;
        width: 100%;
        overflow: hidden;
    }

    .person-avatar-list {
        width: 100%;
        height: 2.07rem;
    }
    .person-avatar-list > .person-avatar-list-wrapper {
        width: 100%;
        display: flex;
    }
    .person-avatar-list > .person-avatar-list-wrapper > .swiper-slide-thumb-active > div{
        border: 0.03rem solid rgb(0,228,255);
    }
    .person-avatar-list > .person-avatar-list-wrapper > .person-avatar-list-item{
        width: 1.73rem;
        height: 2.07rem;
        display: flex;
        align-items: center;
    }
    .person-avatar-list > .person-avatar-list-wrapper > .person-avatar-list-item > div{
        width: 1.73rem;
        height: 1.73rem;
        border-radius: 0.09rem;
        background-color: #101627;
        box-shadow: inset 0 0.5px 0.5px hsl(0deg 0% 100% / 24%);
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .person-avatar-list > .person-avatar-list-wrapper > .person-avatar-list-item > div > img{
        width: .91rem;
        height: 1.46rem;
        object-fit: cover;
    }
    .person-confirm{
        margin-top: 1rem;
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: center;
    }
    .person-confirm > div{
        width: 5.2rem;
        height: 1.5rem;
        background: url('/bgi/person-select/confirm-select-button.png') no-repeat center center;
        background-size: cover;
    }
</style>

